import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, NgZone } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { CdkDialogContainer, Dialog, DialogConfig } from '@angular/cdk/dialog';
import { FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
	selector: 'tk-modal-container',
	templateUrl: './admin-modal-container.component.html',
	styleUrls: ['./admin-modal-container.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminModalContainerComponent extends CdkDialogContainer {
	constructor(
		private dialog: Dialog,
		@Inject(DOCUMENT) _document: any,
		_elementRef: ElementRef<HTMLElement>,
		_focusTrapFactory: FocusTrapFactory,
		_changeDetectorRef: ChangeDetectorRef,
		_config: DialogConfig,
		_interactivityChecker: InteractivityChecker,
		_ngZone: NgZone,
		_overlayRef: OverlayRef,
	) {
		super(_elementRef, _focusTrapFactory, _document, _config, _interactivityChecker, _ngZone, _overlayRef);
	}

	portal?: Portal<any>;

	close() {
		this.dialog.closeAll();
	}
}
