<section class="header">
	<button (click)="goBack()" class="action__back">
		<tk-icon icon="arrow_back" size="20" />
	</button>

	<h4 class="title">{{ authorId ? "Редактирование автора" : "Создание нового автора" }}</h4>
</section>

<form [formGroup]="form" class="form" (ngSubmit)="submit()">
	<section class="form__fields">
		<section class="form__row">
			<tk-avatar-upload-form-control formControlName="avatar" />
			<section class="form__content">
				<p class="form__value__name">{{ form.value.name ?? '' }}</p>
				<p class="form__value__position">{{ form.value.position ?? '' }}</p>
			</section>
		</section>
		<tk-form-field>
			<tk-label>Имя</tk-label>
			<input formControlName="name" tkInput />
			<tk-form-field-info>
				<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('name')?.errors" />
			</tk-form-field-info>
		</tk-form-field>
		<tk-form-field>
			<tk-label>Позиция</tk-label>
			<input formControlName="position" tkInput />
			<tk-form-field-info>
				<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('position')?.errors" />
			</tk-form-field-info>
		</tk-form-field>
	</section>

	<section class="action__group">
		<ng-container *ngIf="authorId; else createActions">
			<button tkButton type="submit">Сохранить</button>
			<tk-button (click)="goBack()" theme="secondary"> Отмена </tk-button>
		</ng-container>
		<ng-template #createActions>
			<button tkButton type="submit">Создать</button>
		</ng-template>
	</section>
</form>
