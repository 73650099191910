<section class="header">
	<span class="header__title">{{ dialogData?.imageUrl ? 'Редактирование фото' : 'Добавление фото' }}</span>
	<button (click)="close()">
		<tk-icon class="header__icon" icon="cross" size="24" />
	</button>
</section>

<image-cropper
	*ngIf="dialogData.imageEvent || dialogData?.imageUrl"
	(imageCropped)="imageCropped($event)"
	[imageChangedEvent]="dialogData.imageEvent"
	[imageURL]="dialogData.imageUrl ?? ''"
	[maintainAspectRatio]="true"
	[roundCropper]="true"
	[aspectRatio]="1 / 1"
></image-cropper>

<section class="action__group">
	<tk-button *ngIf="dialogData.imageUrl" (click)="delete()" stretch class="action__delete" theme="ghost">
		<tk-icon icon="trash" />
		Удалить фото
	</tk-button>
	<section class="action__row">
		<tk-button (click)="close()" stretch theme="secondary">
			<tk-icon icon="cross" size="24" />
			Отменить
		</tk-button>
		<tk-button (click)="submit()" stretch>
			<tk-icon icon="success" size="24" />
			Сохранить
		</tk-button>
	</section>
</section>
