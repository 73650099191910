<section class="header">
	<button (click)="goBack()" class="action__back">
		<tk-icon icon="arrow_back" size="20" />
	</button>

	<h4 class="title">{{ tagId ? "Редактирование тега" : "Создание нового тега" }}</h4>
</section>

<form [formGroup]="form" class="form">
	<tk-form-field>
		<tk-label>Имя</tk-label>
		<input formControlName="name" tkInput />
		<tk-form-field-info>
			<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('name')?.errors" />
		</tk-form-field-info>
	</tk-form-field>

	<section class="action__group">
		<ng-container *ngIf="tagId; else createActions">
			<tk-button (click)="submit()"> Сохранить </tk-button>
			<tk-button (click)="goBack()" theme="secondary"> Отмена </tk-button>
		</ng-container>
		<ng-template #createActions>
			<tk-button (click)="submit()"> Создать </tk-button>
		</ng-template>
	</section>
</form>
