import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Article } from '@models/article';

@Component({
	selector: 'tk-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPreviewComponent {
	articleData: Article | null = null;
	articleContent: any;

	constructor(@Inject(DIALOG_DATA) data: any) {
		this.articleData = data.previewData;
		this.articleContent = data.content;
	}
}
