<div class="wrap">
	<div>
		<a class="logo" routerLink="/">
			<tk-icon icon="tk_logo" />
		</a>
		<div class="list">
			<a class="list__item" routerLink="/articles" routerLinkActive="active">
				<tk-icon icon="doc_used" />
			</a>
			<a class="list__item" routerLink="/mail" routerLinkActive="active">
				<tk-icon icon="mail" />
			</a>
			<a class="list__item" routerLink="/authors" routerLinkActive="active">
				<tk-icon icon="users" />
			</a>
			<a class="list__item" routerLink="/tags" routerLinkActive="active">
				<tk-icon icon="tag" />
			</a>
		</div>
	</div>
	<a (click)="logout()" class="exit">
		<tk-icon icon="exit" />
	</a>
</div>
