import { BlockTool, BlockToolData } from '@editorjs/editorjs';
import { TypeEnum } from '@models/enums/type.enum';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '../../../../../services/image.service';
import { QuoteToolData } from '@models/editor-components';
import { removeNbsp } from './remove-nbsp';

export class QuoteTool implements BlockTool {
	data: QuoteToolData;
	wrapper: HTMLElement | null = null;
	http: HttpClient;
	authorAvatar: string | null = '/assets/images/default_avatar.webp';

	constructor({ data }: BlockToolData, http: HttpClient) {
		this.data = data;
		this.http = http;
		this.authorAvatar = this.data.authorAvatarPath ? this.data.authorAvatarPath : this.authorAvatar;
	}
	static get toolbox() {
		return {
			title: 'Quote',
			icon: 'Q',
		};
	}
	render() {
		this.wrapper = document.createElement('div');
		this.wrapper.classList.add('quote');

		const text = document.createElement('div');
		text.classList.add('quote__text');
		text.contentEditable = 'true';
		text.innerHTML = this.data.text || '';

		const author = document.createElement('div');
		author.classList.add('author');

		const authorAvatar = document.createElement('div');
		authorAvatar.classList.add('author__avatar');
		authorAvatar.style.backgroundImage = this.authorAvatar ? "url('" + this.authorAvatar + "')" : '';

		authorAvatar.addEventListener('click', event => {
			const fileInput = document.createElement('input');
			fileInput.type = 'file';
			fileInput.click();

			fileInput.addEventListener('change', (event: Event) => {
				const files = (event?.target as HTMLInputElement)?.files;
				if (!files?.length) {
					return;
				}
				ImageService.uploadImage((event?.target as HTMLInputElement)?.files![0], TypeEnum.AUTHORAVATAR).subscribe(
					(data: Partial<{ url: string }>) => {
						if (!data.url) {
							return;
						}
						authorAvatar.style.backgroundImage = "url('" + data.url + "')";
						this.authorAvatar = data.url;

						setTimeout(() => {
							authorName.focus();
						}, 100);
					},
				);
			});
		});

		author.appendChild(authorAvatar);

		const authorWrap = document.createElement('div');
		authorWrap.classList.add('author__wrap');

		author.appendChild(authorWrap);

		const authorName = document.createElement('div');
		authorName.classList.add('author__name');
		authorName.contentEditable = 'true';
		authorName.innerHTML = this.data.authorName || '';

		authorWrap.appendChild(authorName);

		const authorPosition = document.createElement('div');
		authorPosition.classList.add('author__position');
		authorPosition.contentEditable = 'true';
		authorPosition.innerHTML = this.data.authorPosition || '';

		authorWrap.appendChild(authorPosition);

		this.wrapper.appendChild(text);

		this.wrapper.appendChild(author);

		return this.wrapper;
	}

	save(blockContent: HTMLInputElement) {
		this.data = {
			text: removeNbsp(blockContent.getElementsByClassName('quote__text')[0].innerHTML),
			authorName: removeNbsp(blockContent.getElementsByClassName('author__name')[0].innerHTML),
			authorPosition: removeNbsp(blockContent.getElementsByClassName('author__position')[0].innerHTML),
			authorAvatarPath: this.authorAvatar ? this.authorAvatar : '',
		};
		return this.data;
	}
}
