@if (data?.title) {
	<h3>{{ data.title }}</h3>
}
<tk-button-group>
	@if (data?.btn) {
		<tk-button (click)="submitForm()" [tkDialogClose]="true" theme="primary">
			{{ data.btn }}
		</tk-button>
	}
	<tk-button theme="secondary" (click)="closeModal()">Отменить</tk-button>
</tk-button-group>
